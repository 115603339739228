.dropdown-filter {
    position: relative;

    .dropdown {
        .dropdown-item-text {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0;
        }
    }
}