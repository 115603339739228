.dashboard {
    background-color: #f8f9fa;
    padding-top: 15px;

    & > .filter-container {
        margin-bottom: 30px;
    }

    & > div {
        position: relative;
    }
}
