.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;
    }

    .filter-container {
        margin-right: 20px;
    }
}