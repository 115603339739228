.navbar {
    .container-fluid {
        display: flex;
        justify-content: space-between;

        & > div {
            display: flex;

            .navbar-brand {
                color: white;
            }
        }

        .navbar-nav {
            .nav-link {
                color: white !important;
                margin-left: 10px;

                &:hover {
                    color: white;
                }

                &.active {
                    text-decoration: underline;
                }
            }

            .username {
                color: white;
            }

            &.user {
                display: flex;
                align-items: center;
            }
        }

    }
}