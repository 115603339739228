.saved-filters-container {
    display: flex;
    align-items: center;

    .saved-filters-select {
        width: 100px;
    }

    .btn {
        margin-left: 10px;
    }
}