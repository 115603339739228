.question-quality {
    .filter {
        .filter-by-duration {
            margin-bottom: 10px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 20px;
        }

        background-color: white;
        padding: 30px 20px;

        .form-check {
            margin-bottom: 10px;

            label {
                font-size: 15px;
            }
        }

        .filter-by-sections {
            margin-bottom: 30px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 20px;
            padding-top: 10px;
        }

        .filter-by-tests .form-check {
            position: relative;

            .test-score-detail {
                position: absolute;
                display: none;
                font-size: 13px;
                background-color: white;
                border: 1px solid gray;
                z-index: 1;
                padding: 10px 20px;
                width: 200px;
                right: -200px;
                top: 0;
            }

            &:hover {
                color: #3b94ff;

                .test-score-detail {
                    display: block;
                }
            }
        }
    }

    .detail-panel {
        background-color: white;
        padding: 20px;

        .test-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .panel {
                width: 45%;
                background-color: lightgray;
                margin-bottom: 15px;
                padding: 10px;

                h3 {
                    font-size: 16px;
                    text-align: center;
                    font-weight: normal;
                }

                div {
                    text-align: center;
                    font-size: 30px;
                }
            }
        }

        .chart {
            margin-bottom: 30px;
        }

        .notice {
            display: flex;
            margin-bottom: 20px;

            span {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-right: 30px;

                &:before {
                    content: "";
                    display: block;
                    width: 30px;
                    height: 5px;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    border-radius: 5px;
                }

                &:nth-child(1):before {
                    background-color: green;
                }

                &:nth-child(2):before {
                    background-color: orange;
                }

                &:nth-child(3):before {
                    background-color: red;
                }

                svg {
                    font-size: 13px;
                }
            }
        }

        .questions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .question {
                margin-bottom: 15px;
                width: 24%;

                h5 {
                    font-size: 14px;
                    margin: 0;
                    cursor: pointer;

                    svg {
                        margin-left: 5px;
                    }
                }

                .ratings {
                    display: flex;
                    width: 100%;
                    height: 5px;

                    span:nth-child(1) {
                        background-color: green;
                        border-radius: 5px;
                    }

                    span:nth-child(2) {
                        background-color: orange;
                    }

                    span:nth-child(3) {
                        background-color: red;
                        border-radius: 5px;
                    }
                }
            }
        }

        .reviews {
            .review {
                background-color: white;
                margin-bottom: 3px;
                padding: 10px;
                border-bottom: 1px solid lightgray;

                .review-header {
                    display: flex;
                    justify-content: space-between;

                    .review-title {
                        font-weight: bold;
                    }

                    .review-rating {
                        display: flex;
                        align-items: center;

                        span {
                            margin-right: 3px;
                        }
                    }

                    .review-author, .review-date {
                        font-style: italic;
                        text-align: right;
                        font-size: 13px;
                    }
                }

                .review-content {
                    margin-top: 10px;
                    font-size: 15px;
                }
            }
        }

        .fb-messenger-feedbacks {
            .feedback {
                border-bottom: 1px solid lightgray;
                padding: 10px 0;
            }
        }

        .filter-and-summary {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filter {
                display: flex;

                & > div {
                    margin-right: 30px;
                }
            }

            .summary {
                font-weight: bold;
            }
        }
    }
}

.question-nps-details-modal {
    .rate, .reported-mistake {
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;

        & > div:first-child {
            display: flex;
            align-items: center;

            .date {
                font-size: 13px;
                margin-right: 10px;
            }

            .report-mistake-reason {
                font-size: 14px;
                font-style: italic;
            }
        }
    }
}