.chart-panel {
    background-color: white;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 5px;
    position: relative;

    .nav .nav-item.active {
        font-weight: bold;
    }

    .panel-type {
        position: absolute;
        top: 0;
        right: 0;
        background-color: gray;
        color: white;
        font-size: 13px;
        padding: 5px;
        border-bottom-left-radius: 5px;

        span {
            font-style: italic;
            font-size: 12px;
        }
    }

    .tab-content {
        position: relative;
        padding-top: 30px;
    }

    .chart-types {
        position: absolute;
        top: 5px;
        right: 10px;

        img {
            width: 20px;
            margin-left: 15px;
            cursor: pointer;
        }
    }

    .chart-container {
        h3 {
            font-size: 18px;
            margin-bottom: 20px;
            margin-left: 20px;
        }
    }

    .filter-by-category {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        & > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: bold;
            margin-right: 10px;
            font-size: 14px;
            margin-bottom: 10px;

            .MuiCheckbox-root {
                padding: 0;
            }
        }
    }

    .custom-tooltip {
        background-color: white;
        padding: 10px;
        border: 1px solid gray;
        min-width: 200px;

        .custom-tooltip-label {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .custom-tooltip-value {
            margin-bottom: 5px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }

        .custom-tooltip-total {
            font-size: 14px;
            font-weight: bold;
        }
    }
}