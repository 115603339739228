.ab-testing-container {
    background-color: #f6f7f9;
    padding-top: 50px;
    padding-bottom: 50px;

    .abtests {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .abtest {
            box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
            padding: 20px 15px;
            width: 30%;

            .abtest-title {
                font-weight: bold;
                font-size: 18px;
            }

            .abtest-meta {
                font-size: 13px;
                color: gray;
            }

            .abtest-versions {
                & > div {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;

                    .abtest-version-title, .abtest-version-conversion-rate {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
