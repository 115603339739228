.sidebar {
    background-color: white;
    width: 190px;
    height: 100vh;
    padding-top: 15px;
    position: absolute;

    & > div {
        margin-bottom: 1px;
        cursor: pointer;
        padding: 12px 20px;

        &:hover {
            background-color: #ececec;
        }

        &.active {
            font-weight: bold;
        }

        &.disabled {
            cursor: default;
            color: #aaa;
        }
    }
}