/*wrapper*/
.card {
	background-color: rgb(190, 188, 188) !important;
	margin-top: 20px;
}
.Collapsible {
	margin: 20px;
	padding: 10px 15px;
	border-radius: 10px;
	width: 100%;
}
.mySelect {
	margin: 20px;
	width: 50%;
}
.title {
	margin: 20px;
}
.loading {
	margin: 20px;
	width: fit-content;
	height: 38px;
	font-size: large;
	font-weight: 500;
}
.big-content {
	display: grid;
	grid-template-columns: 1.2fr 1fr;
}
.collapsible-panel {
	width: 100%;
	display: flex;
	align-items: baseline;
}
.Collapsible__contentInner {
	padding: 10px;
	border: 1px solid lightgray;
	border-top: 0;
	p {
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.Collapsible__trigger {
	display: block;
	font-weight: 400;
	text-decoration: none;
	position: relative;
	border: 1px solid white;
	padding: 10px;
	background: #0d6efd;
	cursor: pointer;
	color: white;
	&:after {
		font-family: "FontAwesome";
		content: "\0056";
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
		transition: transform 300ms;
	}
}
.Collapsible__trigger.is-open {
	&:after {
		transform: rotateZ(180deg);
	}
}
.Collapsible__trigger.is-disabled {
	opacity: 0.5;
	background-color: grey;
}
.CustomTriggerCSS {
	background-color: lightcoral;
	transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
	background-color: darkslateblue;
}
.Collapsible__custom-sibling {
	padding: 5px;
	font-size: 12px;
	background-color: #cbb700;
	color: black;
}
.wrapper {
	background: rgb(241, 237, 237);
	margin-top: 20px;
	padding: 10px 15px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
}
.selection {
	display: grid;
	grid-template-columns: 1fr 10fr 1fr;
	width: 100%;
	height: fit-content;
	align-items: baseline;
}
.selection_collapse {
	display: grid;
	grid-template-columns: 1fr 10fr 1fr;
	width: 100%;
	height: fit-content;
}
.question-wrapper {
	margin: 20px;
}
.question {
	width: fit-content;
	margin-bottom: 10px;
	height: fit-content;
}
.card-body {
	justify-content: space-between;
	display: flex;
	gap: 20px;
}
.button_group {
	display: flex;
	gap: 30px;
}
.name_group {
	display: grid;
	grid-template-rows: auto;
	vertical-align: auto;
}
.nav_bar {
	display: grid;
	grid-template-columns: 20fr 1fr;
	align-items: center;
	padding-left: 150px;
}
.card-title {
	font-style: italic;
}
.is-active {
	text-decoration-line: underline;
	color: white;
}
.icon_green {
	color: green;
}
.icon_red {
	color: red;
}
