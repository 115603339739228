.time-filter-container {
    position: relative;

    .custom-time-container {
        position: absolute;
        width: 200px;
        right: 0;
        z-index: 100;
        background-color: white;
        padding: 10px;
        border: 1px solid #ddd;
    }
}